<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "base",
    },
    background: {
      type: [Number, String],
      default: 1,
    },
  },
  data: () => ({
    backgroundTypes: {
      1: {
        color:
          "bg-[linear-gradient(92.04deg,_#37AEDD_0.59%,_#4E60D8_52.15%,_#A990CF_99.48%)]",
        image: "/images/bg-banner-1.svg",
      },
      2: {
        color: "bg-[linear-gradient(101.86deg,_#7271DA_0%,_#2426AD_100%)]",
        image: "/images/bg-banner-1.svg",
      },
      3: {
        color: "bg-gray-900",
        image: "/images/bg-banner-2.svg",
      },
      4: {
        color: "bg-[linear-gradient(101.86deg,_#E8C37C_0%,_#D98140_100%)]",
        image: "/images/bg-banner-2.svg",
      },
      5: {
        color: "bg-gray-900",
        image: "/lottie/feathers.json",
      },
      6: {
        color:
          "bg-[linear-gradient(102.77deg,_#190F56_8.37%,_#277B96_49.32%,_#491689_94.08%)]",
        image: "/images/bg-banner-2.svg",
      },
      7: {
        color:
          "bg-[linear-gradient(92.04deg,_#FF7F7F_0.59%,_#E95353_52.15%,_#E03A3A_99.48%)]",
        image: "/images/bg-banner-1.svg",
      },
      8: {
        color:
          "bg-[linear-gradient(92.04deg,_#F3B344_0.59%,_#F3B344_52.15%,_#F3B344_99.48%)]",
        image: "/images/bg-banner-1.svg",
      },
    },
    sizes: {
      sm: {
        card: "p-6 rounded-2xl",
        title: "text-2xl font-medium mb-1",
        description: "text-sm font-normal",
        content: "flex flex-nowrap",
        actions: "ml-6",
      },
      base: {
        card: "pt-10 pb-12 px-12 rounded-2xl",
        title: "text-4xl font-medium mb-2",
        description: "text-lg font-normal",
        content: "",
        actions: "mt-6",
      },
    },
  }),
};
</script>

<template>
  <div class="relative overflow-hidden text-white" :class="[sizes[size].card]">
    <div
      class="pointer-events-none absolute left-0 top-0 h-full w-full"
      :class="[backgroundTypes[background].color]"
    >
      <lottie-animation
        v-if="backgroundTypes[background].image.includes('lottie/')"
        class="absolute -left-[140px] -top-[260px] !h-auto !w-[1120px] rotate-[160deg]"
        :path="backgroundTypes[background].image"
      />
      <img
        v-else
        :src="backgroundTypes[background].image"
        class="h-full w-full object-cover object-center"
        alt="Background"
      />
    </div>
    <div class="relative" :class="sizes[size].content">
      <img
        v-if="['base'].includes(size)"
        src="/logo/rise-white.png"
        class="mb-7 block h-[43px] w-auto"
        alt="Rise"
        :class="[{ 'mx-auto': centered }]"
      />
      <img
        v-else
        src="/logo/rise-symbol-white.svg"
        class="mr-6 block h-[48px] w-[48px]"
        alt="Rise"
      />
      <div class="min-w-0 flex-1">
        <div
          v-if="title"
          :class="[sizes[size].title, { 'text-center': centered }]"
        >
          {{ title }}
        </div>
        <div
          v-if="description || $slots.description"
          :class="[sizes[size].description, { 'text-center': centered }]"
        >
          <slot name="description">
            {{ description }}
          </slot>
        </div>
      </div>
      <div
        v-if="$slots.actions"
        class="flex flex-wrap"
        :class="[{ 'justify-center': centered }, sizes[size].button]"
      >
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>
